import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28657ca1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gameList" }
const _hoisted_2 = { class: "game" }
const _hoisted_3 = ["onClick", "title"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Effect = _resolveComponent("Effect")!

  return (_openBlock(), _createElementBlock("section", {
    class: "list J_List",
    style: _normalizeStyle(_ctx.bgImage ? {backgroundImage: `url(${_ctx.bgImage})`} : {})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
          return (_openBlock(), _createElementBlock("figure", {
            key: item.game_id,
            onClick: ($event: any) => (_ctx.onClick(item)),
            title: item.name,
            onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseenter && _ctx.onMouseenter(...args))),
            onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseleave && _ctx.onMouseleave(...args))),
            onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTouchstart && _ctx.onTouchstart(...args))),
            class: "J_Game_Item"
          }, [
            _createElementVNode("img", {
              src: item.icon_link,
              alt: item.name
            }, null, 8, _hoisted_4),
            _createElementVNode("figcaption", null, _toDisplayString(item.name), 1)
          ], 40, _hoisted_3))
        }), 128)),
        _createVNode(_component_Effect, {
          show: _ctx.show,
          class: "J_Effect"
        }, null, 8, ["show"])
      ])
    ]),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMore && _ctx.onMore(...args)))
          }, _toDisplayString(_ctx.t('list.more')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}