/*
 * @Description: 游戏列表
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { ListItem } from '@/model/listModel'
import { List } from '@/model/list'

// 获取列表
export const getGameList = (url: string, params: any) => {
  return https().request<RootObject<List<ListItem>>>(`api/demoGame/${url}`, Method.GET, params, ContentType.form)
}

// 获取BB列表
export const getBBGameList = (config: any, lang: string, params: any) => {
  return https(config).request<RootObject<any>>(`game_list/${lang}`, Method.GET, params, ContentType.form)
}

// 启动游戏
export const launchGame = (config: any, id: number, params: any) => {
  return https(config).request<RootObject<any>>(`launch_game/${id}`, Method.GET, params, ContentType.form)
}
