
import { defineComponent, onBeforeMount, onMounted, reactive, toRefs, watch } from 'vue'
import { getBBGameList, launchGame } from '@/apis/list'
import { ListItem } from '@/model/listModel'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import Effect from './components/Effect.vue'
import { sha256 } from 'js-sha256'
import { useI18n } from 'vue-i18n'
import networkConfig from '@/config/default/net.config'

function getPosition(element: any) {
  // 计算x坐标
  let actualLeft = element.offsetLeft
  let current = element.offsetParent
  while (current !== null) {
    actualLeft += current.offsetLeft
    current = current.offsetParent
  }
  // 计算y坐标
  let actualTop = element.offsetTop
  current = element.offsetParent
  while (current !== null) {
    actualTop += (current.offsetTop + current.clientTop)
    current = current.offsetParent
  }
  // 返回结果
  return { x: actualLeft, y: actualTop }
}

export default defineComponent({
  components: {
    Effect
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { t } = useI18n()
    const options = {
      autoPlay: 1,
      bringToFront: true
    }
    const dataMap = reactive({
      bgImage: '',
      gameType: '',
      total: 0,
      listQuery: {
        page: 1,
        size: 60,
        domain: window.location.origin
      },
      list: Array<ListItem>(),
      show: false,
      showMore: false
    })
    const methods = reactive({
      config() {
        return {
          baseURL: networkConfig.BBHost,
          headers: {
            time: Date.parse(String(new Date())) / 1000,
            sign: sha256(Date.parse(String(new Date())) / 1000 + '_h87987183%@#ash@#!dfas')
          }
        }
      },
      async getList() {
        if (!dataMap.gameType) return
        const gameType = new Map([['slot', 10], ['hunter', 11], ['arcade', 12], ['chess', 16]]).get(dataMap.gameType)
        const lang = store.state.app.language.split('-')[0] || 'en'
        const res = await getBBGameList(methods.config(), lang, { ...dataMap.listQuery, type: gameType })
        const list = res?.data ?? []
        dataMap.list = [...dataMap.list, ...list]
        if (list.length < 60) dataMap.showMore = false
        else dataMap.showMore = true
      },
      onMore() {
        dataMap.listQuery.page = dataMap.listQuery.page + 1
        methods.getList()
      },
      async onClick(item: ListItem) {
        const lang = store.state.app.language.split('-')[0] || 'en'
        // eslint-disable-next-line
        const res = await launchGame(methods.config(), item.game_id, { language: lang, coin_type: '' })
        if (res?.code === 1001) window.open(res.data.game_url, '_blank')
      },
      onMouseenter(e: any) {
        const position = getPosition(e.target)
        const el = window.document.querySelector('.J_Effect') as any
        const game = window.document.querySelector('.J_Game_Item') as any

        if (el) el.style = `width:${game.offsetWidth + 50}px;left:${position.x - 20}px;top:${position.y - 20}px`
        dataMap.show = true
      },
      onMouseleave() {
        dataMap.show = false
      },
      onTouchstart(e: any) {
        methods.onMouseenter(e)
      }
    })

    onBeforeMount(() => {
      switch (route.query.type) {
        case '1':
          dataMap.gameType = 'slot'
          break
        case '2':
          dataMap.gameType = 'hunter'
          break
        case '3':
          dataMap.gameType = 'chess'
          break
        case '4':
          dataMap.gameType = 'arcade'
          break
        default:
          dataMap.gameType = ''
      }
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_List')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-list')
      }

      if (store.state.app.config.resource) {
        dataMap.bgImage = `${store.state.app.config.resource}/bimg/${store.state.app.language}/game/${dataMap.gameType}.jpg`
        methods.getList()
      }
    })

    watch(() => store.state.app.config.resource, (n, o) => {
      if (dataMap.gameType && n && n !== o) {
        dataMap.bgImage = `${n}/bimg/${store.state.app.language}/game/${dataMap.gameType}.jpg`
        dataMap.list = []
        dataMap.listQuery.page = 1
        methods.getList()
      }
    })

    watch(() => store.state.app.language, (n) => {
      dataMap.bgImage = `${store.state.app.config.resource}/bimg/${n}/game/${dataMap.gameType}.jpg`
      dataMap.list = []
      dataMap.listQuery.page = 1
      methods.getList()
    })

    return {
      t,
      options,
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
