import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dea0a12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "effect" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (item, key) => {
      return _withDirectives((_openBlock(), _createElementBlock("img", {
        key: key,
        src: item
      }, null, 8, _hoisted_2)), [
        [_vShow, _ctx.current === key]
      ])
    }), 128))
  ]))
}